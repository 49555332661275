// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import {WOW} from 'wow.js';
import "css/application";
import "bootstrap";




// import ReactDOM from "react-dom";
// import reportWebVitals from "./reportWebVitals";
// import store from "../redux/store";
// import { Provider } from "react-redux";
// import "./styles/reset.css";



// import "../js/owl-carousel.js";
// import "../js/animation.js";
// import "../js/imagesLoaded.js";
// import "../js/custom.js";
// import "../js/scripts.js";

// import "../js/isotope.js";
// import "../js/tabs.js";



// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
