import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types"
import { Provider } from "react-redux";
import store from "../redux/store";
import App from "./App";
import axios from "axios";

class AppIndex extends React.Component {
  constructor(props) {
    super(props);
    console.log(props)

    this.state = {
      id: props.contract_id,
      abi: "",
      contract_address: "",
      display_cost: 0,
      gas_limit: 0,
      max_supply: 0,
      name: "",
      network: {},
      pabi: {},
      scan_link: "",
      wei_cost: 0,
      ready: false
    };
  }

  componentDidMount() {
    this.loadWidget();
  }

  loadWidget() {
    let _this = this;

    axios.get(`/v1/mints/${this.state.id}`)
      .then(function (response) {
        let data = response.data.data.attributes
        _this._setState(response.data.data.attributes);
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  _setState = (state) => {
    this.setState({ ...state, ...{ready: true} });
  };

  render () {
    return (
      <>
        {
          this.state.ready &&
          <Provider store={store}>
            <App greeting={this.state.greeting} config={this.state} />
          </Provider>
        }
      </>
    );
  }
}

export default AppIndex
