import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import Mint from "./Mint";

export const App = (props) => {

  return (
    <>
      <div className="mint-app-shibago" style={{ padding: 25 }}>
        <Mint config={props.config}/>
      </div>
    </>
  );
}

export default App
