import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

const qty = (title, setTitle) => useState('');

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #FF5E00;
  padding: 10px;
  font-size: 25px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 230px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
      color: #fff;
      background-color: #c09;
      border-color: #bf008f;
  }
  :hover {
    color: #fff;
    background-color: #be5712;
    border-color: #bf008f;
}
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 0px;
  border: 1px solid #00000066;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 30px;
  color: var(--primary-text);
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  // -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  // -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const MaxRoundButton = styled.button`
  padding: 10px;
  border: 1px solid #00000066;
  border-left: 0px solid #00000066;
  background-color: #15e284;
  padding: 10px;
  margin-left: 5px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 65px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  // -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  // -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const Mint = (props) => {

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint Crypto Shiba Go.`);
  const [mintAmount, setMintAmount] = useState(1);

  const [CONFIG, SET_CONFIG] = useState(props.config);

  const claimNFTs = () => {
    let cost = CONFIG.wei_cost;
    let gasLimit = CONFIG.gas_limit;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.name}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.contract_address,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.name} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const inputMintAmount = () => {
    alert(qty);
    let newMintAmount = mintAmount;
    setMintAmount(newMintAmount);
  };

  const maxMintAmount = () => {
    let newMintAmount = 50;
    setMintAmount(newMintAmount);
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = parseInt(mintAmount) + 1;
    if (newMintAmount > 50) {
      newMintAmount = 50;
    }
    setMintAmount(newMintAmount);
  };

  const inputUpdate = (value) => {
    if (value > 50) {
      value = 50;
    }
    setMintAmount(value);
  }

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <>
      <ResponsiveWrapper flex={1} style={{ padding: 0, color: "#FFFFFF" }} test>
        <s.Container
          flex={2}
          jc={"center"}
          ai={"center"}
          style={{
            backgroundColor: "var(--accent)",
            padding: 0,
          }}
        >
          <s.TextTitle
            style={{
              textAlign: "center",
              fontSize: 40,
              fontWeight: "bold",
              color: "var(--accent-text)",
            }}
          >
            {data.totalSupply} / {CONFIG.max_supply}
          </s.TextTitle>
          <s.TextDescription style={{ textAlign: "center", color: "var(--primary-text)", }} >
            <StyledLink target={"_blank"} href={CONFIG.scan_link}>
              {truncate(CONFIG.contract_address, 15)}
            </StyledLink>
          </s.TextDescription>
          {Number(data.totalSupply) >= CONFIG.max_supply ? (
            <>
              <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                The sale has ended.
              </s.TextTitle>
              <s.TextDescription
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                You can still find {CONFIG.name} on
              </s.TextDescription>
              <s.SpacerSmall />
              <StyledLink target={"_blank"} href={CONFIG.marketplace_link}>
                {CONFIG.marketplace}
              </StyledLink>
            </>
          ) : (
            <>
              <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)", fontSize:"25px" }} >
                1 {CONFIG.symbol} costs {CONFIG.display_cost}{" "}
                {CONFIG.network.symbol}.
              </s.TextTitle>
              <s.SpacerXSmall />
              <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }} >
                Excluding gas fees.
              </s.TextDescription>
              <s.SpacerSmall />
              {blockchain.account === "" ||
              blockchain.smartContract === null ? (
                <s.Container ai={"center"} jc={"center"}>
                  <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} >
                    Connect to the {CONFIG.network.NAME} network
                  </s.TextDescription>
                  <s.SpacerSmall />
                  <StyledButton onClick={(e) => { e.preventDefault(); dispatch(connect(CONFIG)); getData(); }} >
                    CONNECT
                  </StyledButton>
                  {blockchain.errorMsg !== "" ? (
                    <>
                      <s.SpacerSmall />
                      <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} >
                        {blockchain.errorMsg}
                      </s.TextDescription>
                    </>
                  ) : null}
                </s.Container>
              ) : (
                <>
                  <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} >
                    {feedback}
                  </s.TextDescription>
                  <s.SpacerMedium />
                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <StyledRoundButton
                      style={{ lineHeight: 0.4 }}
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        decrementMintAmount();
                      }}
                    >
                      -
                    </StyledRoundButton>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontSize: "30px",
                        width: "auto",
                        background: "white",
                        height: "auto",
                        borderTop: "1px solid #00000066",
                        borderBottom: "1px solid #00000066",
                        margin: "unset"
                      }}
                    >
                      <input
                        value={mintAmount}
                        onChange={(e) => {inputUpdate(e.target.value)}}
                        maxLength={2}
                        style={{
                          width: "60px",
                          height: "48px",
                          fontSize: "30px",
                          textAlign: "center",
                        }}
                      />
                    </s.TextDescription>
                    <StyledRoundButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        incrementMintAmount();
                      }}
                    >
                      +
                    </StyledRoundButton>
                    <MaxRoundButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        maxMintAmount();
                      }}
                    >
                        MAX
                    </MaxRoundButton>
                  </s.Container>
                  <s.SpacerSmall />
                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <StyledButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs();
                        getData();
                      }}
                    >
                      {claimingNft ? "BUSY" : "BUY"}
                    </StyledButton>
                  </s.Container>
                </>
              )}
            </>
          )}
          <s.TextDescription style={{ textAlign: "center", color: "white", fontSize: "12px", padding: "30px 0px 0px" }} >
            Please make sure you are connected to the right network (
            {CONFIG.network.name} Mainnet) and the correct address.
          <s.SpacerXSmall />
        </s.TextDescription>
        </s.Container>
      </ResponsiveWrapper>
    </>
  );
}

export default Mint
